import React from "react"
import { Container } from "reactstrap"

import "./hero.scss"

import companyThumbnail from "../images/fahr_erlebnis_weiss_transparent.png"


const Hero = ({ title, description }) => {
  return (
    <div className="hero" id="hero">
      <section className="h-100 d-flex align-items-center text-center bg-dark-40">
        <Container>
        <img
                src={companyThumbnail}
                alt="Company Thumbnail"
                className="img-head"
              />    
          <main className="hero-content">
                 <h1 className="title">{title}</h1>
           
          </main>
        </Container>
      </section>
    </div>
  )
}

export default Hero
