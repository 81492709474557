import React from "react"
import { Container, Row, Col } from "reactstrap"
// import { Link } from "gatsby"
// import List2 from "../components/list2"

// import Square from "../assets/svg/solid/square.svg"
// import companyThumbnail from "../images/kuh_land.jpeg"

import "./about.scss"

const Top = () => {
  return (
    <div className="about pad">
      <Container>
        <Row>
          <Col md="12" lg="12">
            <section className="about-content">
              
             {/* <h3>Wir entwicklen den Landwirtschaftsbetrieb nachhaltig und innovativ.</h3>  <br></br> */}
              <p className="about-description text-secondary ">
              Nebst den Mutterkühen mit ihren Kälbern leben auf dem Landwirtschafsbetrieb zurzeit Schafe und Hasen.
               Alle Tiere werden artgerecht mit einem grosszügigen Platzangebot gehalten. 
               Zudem betreiben wir in der Landwirtschaft vielfältigen Ackerbau mit Spezialkulturen 
               und engagieren uns aktiv in einem ökologischen Vernetzungsprojekt mit einem hohen Anteil an ökologisch
                wertvollen Biodiversitätsflächen. 
              Den Betrieb stellen wir schrittweise auf Bio um. 
              </p>
              
            
            </section>
          </Col>
          
        </Row>
      </Container>
    </div>
  )
}

export default Top
