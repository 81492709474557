import React from "react"
import { graphql } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"

// ---------- Themes colors ----------
import "../themes/green.scss"
// import "../themes/orange.scss"

// ---------- Components ----------
import Hero from "../components/hero"
import Top from "../components/top"
// import About from "../components/about"

import GridImages from '../components/gridImages'



const IndexPage = ({ data: {
  allContentfulBilderLandwirtschaft,
  site,
} }) => {
  const { fullTitle, description } = site.siteMetadata
  


  return (
    <div className="test-q">
      <Hero title={fullTitle} description={description} />
     <Top/>
     <GridImages images={allContentfulBilderLandwirtschaft.edges[0].node.bilder} />

    </div>
  )
}

export const projectsQuery = graphql`
  query FeaturedProjectsQuery {
    allContentfulBilderLandwirtschaft {
      edges {
        node {
          id
          bilder {
            id
            file {
              url
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        fullTitle
        description
        about
      }
    }
  }
`

export default IndexPage
